<template>
  <div>
    <div class="content-tab border-top-line mb-5">
      <v-container fluid>
        <v-row v-if="aDisputes.length > 0">
          <v-col
            class="pa-0"
            cols="12"
            sm="12"
          >
            <div v-if="isResponsive">
              <div
                v-for="(item, index) in aDisputes"
                :key="index"
                class="card-content mb-5 pa-3 position-relative"
              >
                <p class="card-txt-title mon-bold">{{ item.oPurchaseOrder.sFolioId }}</p>
                <div class="display-flex align-items-center">
                  <p class="card-txt-inf mon-bold">{{ texts.disputes.tableInProcess.headers[1] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">{{ item.oCustomer.sName }}</p>
                </div>
                <div class="display-flex align-items-center">
                  <p class="card-txt-inf mon-bold">{{ texts.disputes.tableInProcess.headers[2] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">${{ formatMoney(item.dTotalPrice) }} USD</p>
                </div>
                <div class="display-flex align-items-center">
                  <p class="card-txt-inf mon-bold">{{ texts.disputes.tableInProcess.headers[3] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">{{ item.tCreatedAt }}</p>
                </div>
                <div class="display-flex align-items-center">
                  <p class="card-txt-inf mon-bold">{{ texts.disputes.tableInProcess.headers[4] }}</p>
                  <v-spacer />
                  <p class="card-txt-res resalt mon-regular">{{ item.oStatus.sStatusName }}</p>
                </div>
                <div>
                  <v-btn
                    elevation="0"
                    @click="disputeDetail(item)"
                    class="button-primary mon-bold mb-2"
                    width="100%"
                  >
                    {{
                      (selectLanguage == 'sp')
                      ? 'Ver detalle'
                      : 'Show detail'
                    }}
                  </v-btn>
                </div>
              </div>
              <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                <div class="pagination-styles">
                  <v-pagination
                    v-model="iCurrentPage"
                    :length="lengthPage"
                    color="#FFC556"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                  ></v-pagination>
                </div>
              </div>
            </div>
            <div
              v-else
              class="width-100"
            >
              <div class="card-content">
                <v-simple-table
                  height="380px"
                  fixed-header
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="(item, index) in texts.disputes.tableInProcess.headers"
                          :key="index"
                          class="text-left text-header-title mon-bold"
                        >
                          {{ item }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in aDisputes"
                        :key="index"
                      >
                        <td class="mon-regular">{{ item.oPurchaseOrder.sFolioId }}</td>
                        <td class="mon-regular">{{ item.oCustomer.sName }}</td>
                        <td class="mon-regular">${{ formatMoney(item.dTotalPrice) }} MXN</td>
                        <td class="mon-regular">{{ item.tCreatedAt }}</td>
                        <td class="mon-regular resalt">{{ item.oStatus.sStatusName }}</td>
                        <td>
                          <v-btn
                            @click="disputeDetail(item)"
                            icon
                          >
                            <v-icon
                              color="#283C4D"
                              size="18px"
                            >
                              mdi-eye
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                  <div class="pagination-styles">
                    <v-pagination
                      v-model="iCurrentPage"
                      :length="lengthPage"
                      color="#FFC556"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <div>
              <div
                style="height: 350px !important;"
                class="card-content display-flex align-items-center justify-content-center"
              >
                <div>
                  <!-- <div class="display-flex align-items-center justify-content-center">
                    <img
                      class="empty-image"
                      src="@/assets/images/oops.png"
                      alt=""
                    >
                  </div> -->
                  <p class="empty-text mon-regular">{{ texts.disputes.textEmpty }}</p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersTabContentOneLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      iCurrentPage: 1,
      lengthPage: 0,
      aDisputes: [],
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getDisputes();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    formatDate: function (date) {
      var new_date = MOMENT(date, "YYYY/MM/DD");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    disputeDetail: function (item) {
      this.$store.commit("setDialogDetailDispute", {
        active: true,
        arr: item,
      });
    },
    getDisputes: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/claim-tickets`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 10,
          iFilter: 1,
          sSearch: this.sSearch,
          tStart: this.startDate,
          tEnd: this.endDate,
          aClaimTicketStatus: this.disputeStatus,
          bPaid: this.disputePaymentStatus,
        },
      })
        .then((response) => {
          let arr = response.data;

          this.aDisputes = arr.results;
          this.lengthPage = arr.iNumPages;
          this.iCurrentPage = arr.iCurrentPage;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    startDate: function () {
      return this.$store.state.startDate;
    },
    endDate: function () {
      return this.$store.state.endDate;
    },
    refresh: function () {
      return this.$store.refresh;
    },
    disputeStatus: function () {
      return this.$store.state.disputeStatus;
    },
    disputePaymentStatus: function () {
      return this.$store.state.disputePaymentStatus;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getDisputes();
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.iCurrentPage = 1;
      this.getDisputes();
    }, 500),
    endDate: function () {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getDisputes();
      }
    },
    startDate: function () {
      this.iCurrentPage = 1;
      this.getDisputes();
    },
    iCurrentPage: function () {
      this.getDisputes();
    },
    refresh: function () {
      this.getDisputes();
    },
    disputeStatus: function () {
      this.iCurrentPage = 1;
      this.getDisputes();
    },
    disputePaymentStatus: function () {
      this.iCurrentPage = 1;
      this.getDisputes();
    },
  },
};
</script>

<style scoped>
.content-empty {
  height: 450px;
}

.content-empty p {
  color: #00000060 !important;
  font-size: 16px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-link {
  color: #0971fb;
  text-decoration: underline;
}

.text-link:hover {
  cursor: pointer;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.content-tab {
  padding: 20px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}

.card-txt-title {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.card-txt-info {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.card-txt-res {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.resalt{
  color: red;
}
</style>